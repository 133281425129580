<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button :to='{name: "emails.templates"}' variant="secondary" size="sm" class="mr-3">
          <BIconGear/> Templates
        </b-button>

        <b-button :to='{name: "emails.settings"}' variant="primary" size="sm">
          <BIconGear/> Settings
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">

      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
          <template #empty>
            no records
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link :to='{name: "emails.email.details", params: {emailId: data.item.id}}'><BIconInfoCircle/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import PageMenu from '@/common/components/PageMenu';
import {BIconGear,BIconInfoCircle} from 'bootstrap-vue';
import PaginationRouter from '@/common/components/Pagination.Router';
export default {
    components: {
      BIconGear,BIconInfoCircle,
      PageMenu,PaginationRouter
    },
    data(){
      return {
        page: {
            title: "Emails",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Emails"
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'createdAt',
              label: "Sent",
              formatter: (value) => {
                return moment(value).format('DD/MM/YYYY HH:mm:ss');
              }
            },
            {
              key: 'emailAddress',
              label: "To"
            },
            {
              key: 'subject',
              label: "Subject"
            },
            {
              key: 'status',
              label: "Status"
            },
            {
              key: 'priority',
              label: "Priority"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        },
        form: null
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'emails',query: query}).href;
      },

      getResults({pageNo},filters){
        this.table.isLoading = true;
        this.$api.get('emails',{
          params: Object.assign({
            perPage: 20,
            page: pageNo,
            orderBy: "datetime",
            arrange: "desc"
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
          this.page.title = `Emails (${data.total})`;
        });
      },
    },
    mounted(){
      this.getResults({pageNo: 1});

      this.$emit('set-sidebar',{active: "emails"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
